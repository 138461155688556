@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');


.intro_section{
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    font-size: 10px;
    color: rgb(78, 78, 78);
}
.intro_section img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}


@media screen and (max-width: 733px){
    .intro_section h3 {
        margin: 15px;
    }
}
@media screen and (max-width: 730px){
    .intro_section img {
        height: 310px;
    }
    
}

@media screen and (max-width: 691px){
    .intro_section h3 {
        margin: 20px;
        font-size: 1rem;
    }
}

@media screen and (max-width: 650px){
 
    .intro_section img {
        width: 100%;
        height: 295px;
        object-fit: cover;
    }
  
    
}


@media screen and (max-width: 548px){
    .intro_section img {
        height: 257px;
    }
}
