/* font-family: 'Aclonica', sans-serif;
font-family: 'Berkshire Swash', cursive;
font-family: 'Fira Sans', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Roboto', sans-serif; */

.App {
  width: 100%;
}
.auth_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  height: fit-content;
  border-radius: 3px;
  border: 1px solid rgb(181, 179, 179);
  background-color: white;
  text-align: center;
}

.auth_wrapper .img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth_wrapper .img span {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: "Times New Roman", Times, serif;
  color: rgb(16, 15, 15);
}
.auth_wrapper .img img {
  width: 60px;
  object-fit: cover;
  margin: auto;
}

.xxx {
  display: flex;
  justify-content: space-around;
}
.xxx .tab {
  width: 50%;
  padding: 20px;
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid rgb(52, 50, 50);
  color: grey;
  font-size: small;
  cursor: pointer;
}

.social {
  display: flex;
  width: 80%;
  margin: 10px auto;
  border-radius: 3px;
  color: white;
  background-color: #0058a9;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.social:hover {
  background-color: #042f58;
}
.social img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin: 2px;
}
.social span {
  margin-left: 1.3rem;
  font-family: "Times New Roman", Times, serif;
  margin-top: 0.6rem;
  font-weight: 400;
}
.or {
  margin: auto;
  font-size: x-small;
  color: grey;
}

.input {
  width: 80%;
  margin: 10px auto;
  border: 1px solid grey;
  padding: 0.7rem;
  display: flex;
  justify-content: space-around;
  border-radius: 3px;
}
.input input {
  border: none;
  outline: none;
}

.dilougue {
  width: auto;
  background-color: #eee;
  color: rgba(0, 0, 0, 0.54);
  padding: 1rem;
  font-size: xx-small;
  font-family: Arial, Helvetica, sans-serif;
}
.dilougue b {
  color: black;
}

.forget {
  width: auto;
  background-color: white;
  padding: 1rem;
  font-size: xx-small;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}
.forget a {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.forget a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.footer {
  height: 70px;
  background-color: #042f58;
  color: white;
  display: grid;
  place-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.footer:hover {
  background-color: #031d35;
  font-size: 16px;
}

/* ----------------home page----------- */

.HomePage {
  min-height: 100vh;
  width: 100%;
  background: white;
}

.navbar {
  background: rgb(243, 243, 243);
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 2px 3px 1px rgba(179, 179, 179, 0.38);
  -moz-box-shadow: 0px 2px 3px 1px rgba(179, 179, 179, 0.38);
  box-shadow: 0px 2px 3px 1px rgba(179, 179, 179, 0.38);
  position: fixed;
  top: 0;
  z-index: 10000;
}

.sidebar {
  position: absolute;
  top: 65px;
  right: 0px;
  background-color: rgb(239, 239, 239, 0.9);
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  border-top: 0.5px solid rgb(204, 203, 203);
  z-index: 11;
  color: black;
  padding: 1rem;
}

.sidebar ul {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  text-align: center;
}

.sidebar ul li {
  font-size: xx-small;
  list-style-type: none;
  cursor: pointer;
  font-weight: 400;
}
.sidebar ul li:first-of-type {
  padding: 10px;
  background: repeating-linear-gradient(138deg, #5cf5d5, transparent 100px);
}
.sidebar ul li:last-of-type {
  padding: 10px;
  background: rgb(255 65 65);
  color: black;
  border-radius: 0px;
  padding: 10px;
}
.sidebar ul li a {
  text-decoration: none;
  color: black;
}

.navbar .img {
  width: 60px;
}

.navbar img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.navbar .rocket {
  display: flex;
  align-items: center;
  margin: 0.2rem;
  position: relative;
  left: 0.5rem;
  cursor: pointer;
  z-index: 10000;
}
.navbar .rocket span {
  font-size: medium;
  color: rgb(70, 66, 66);
  font-weight: 200;
  margin-bottom: 15px;
  font-family: "Pacifico", cursive;
  cursor: pointer;
}
.navbar .search {
  display: flex;
  /* border: 1px solid red; */
  position: relative;
  right: 200px;
}
.navbar .search .cat {
  margin: 5px;
  position: relative;
  left: 270px;
}
.navbar .search .cat label {
  margin: 5px;
  color: rgb(120, 115, 115);
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-small;
}

.navbar .search .search_wrap {
  padding: 0.2rem;
  margin-left: 300px;
  background-color: rgba(209, 203, 203, 0.3);
  border-radius: 30px;
  display: flex;
}
.navbar .search .search_wrap:focus {
  border: 1px solid red;
}

.navbar .search .search_wrap:hover {
  background-color: rgba(209, 203, 203, 0.6);
}
.navbar .search .search_wrap input {
  width: 400px;
  height: 30px;
  margin-left: 1rem;
  border: none;
  outline: none;
  position: relative;
  bottom: 1px;
  font-size: small;
  font-family: cursive;
  background: transparent;
}
.navbar .search .search_wrap input::placeholder {
  position: relative;
  bottom: 2px;
  color: rgb(101, 98, 99);
  font-size: smaller;
}

.search .image {
  border-radius: 50%;
  margin-left: 2px;
  width: 35px;
  height: 35px;
  background-color: rgba(209, 203, 203, 0.3);
  display: grid;
  place-items: center;
  z-index: 10000000;
}
.search .image svg {
  fill: black;
}
.search .image:hover {
  background: rgba(209, 203, 203, 0.5);
}
.search img {
  height: 25px;
  width: 25px;
  position: relative;
  top: 4px;
  left: 4px;
  object-fit: cover;
}
.search select {
  color: rgb(0, 0, 0);
  font-size: 5px;
  padding: 3px;
  border: none;
  outline: 0.2px solid #656565;
  border-radius: 15px;
  -webkit-box-shadow: 0px 2px 3px 1px rgba(179, 179, 179, 0.38);
  -moz-box-shadow: 0px 2px 3px 1px rgba(179, 179, 179, 0.38);
  box-shadow: 0px 2px 3px 1px rgba(179, 179, 179, 0.38);
}
.links {
  display: flex;
  gap: 10px;
  white-space: nowrap;
}
.links a {
  text-decoration: none;
}

.links a:first-child {
  position: relative;
  right: 60px;
  top: 2px;
}

.links a:first-child svg {
  color: black;
  fill: rgb(0, 0, 0);
}
.links a:first-child span {
  margin: 2px;
  color: black;
}
.links a:first-child span:hover {
  margin: 2px;
  color: black;
}

.user {
  position: relative;
  right: 35px;
  display: flex;
  align-items: center;
  background: rgb(241, 237, 237);
  border-radius: 30px;
  padding: 5px;
}

.user:hover {
  background: rgb(231, 224, 224);
}
.user .user_image {
  background-image: url("https://res.cloudinary.com/dttyhvsnv/image/upload/v1677430557/default_pic_gxoa10.png");
  background-position: center center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  overflow: hidden;
  background-size: cover;
}

.user .user_image img {
  width: 100%;
  background-size: cover;
  object-fit: cover;
  height: 100%;
}

.logout {
  position: relative;
  right: 30px;
  border-radius: 4px;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  padding: 5px;
  height: 21px;
  top: 8px;
  text-decoration: none;
  font-size: x-small;
  transition: all 0.2s ease-in-out;
}
.logout:hover {
  text-decoration: underline;
}

.links .write {
  font-weight: 400;
  font-family: cursive;
}

.cc {
  margin-top: 57px;
  width: 100vw;
  height: calc(100vh - 300px);
  object-fit: cover;
}

.errorValidation {
  color: red;
  font-size: xx-small;
}
.RegisterSuccess {
  color: green;
  font-size: xx-small;
}

@media screen and (max-width: 1231px) {
  .links {
    position: relative;
    right: 40px;
  }
  .navbar .search .cat {
    display: flex;
    gap: 6px;
  }
}
@media screen and (max-width: 1188px) {
  .links {
    right: 75px;
  }
}
@media screen and (max-width: 1153px) {
  .links {
    right: 95px;
  }
  .navbar .search .search_wrap input {
    width: 355px;
  }
}
@media screen and (max-width: 1088px) {
  .navbar .search {
    right: 221px;
  }
  .links {
    right: 118px;
  }
  .navbar .search .search_wrap input {
    width: 331px;
  }
  .posts_container .item .right .profile_data {
    top: -9px;
  }
}
@media screen and (max-width: 1058px) {
  .links {
    right: 130px;
  }
  .links a:first-child {
    right: 38px;
  }
  .navbar .search .search_wrap input {
    width: 319px;
  }
}
@media screen and (max-width: 1009px) {
  .links {
    right: 142px;
  }
  .navbar .search {
    right: 222px;
  }
  .navbar .search .search_wrap input {
    width: 298px;
  }
}
@media screen and (max-width: 982px) {
  .links {
    right: 150px;
  }
  .navbar .search .cat {
    left: 266px;
  }
  .navbar .search .search_wrap {
    margin-left: 293px;
  }
}
@media screen and (max-width: 966px) {
  .navbar .search .search_wrap input {
    width: 269px;
  }
}
@media screen and (max-width: 936px) {
  .navbar .search .search_wrap input {
    width: 234px;
  }
}
@media screen and (max-width: 900px) {
  .navbar .search .search_wrap {
    margin-left: 279px;
  }
  .navbar .search .search_wrap input {
    width: 200px;
  }
  .links {
    right: 133px;
  }
}
@media screen and (max-width: 872px) {
  .links {
    right: 142px;
  }
  .navbar .search .search_wrap input {
    width: 179px;
  }
}
@media screen and (max-width: 839px) {
  .navbar .search {
    right: 231px;
  }
  .links {
    right: 156px;
  }
}
@media screen and (max-width: 820px) {
  .links {
    right: 164px;
  }
  .navbar .search .search_wrap input {
    width: 155px;
  }
}
@media screen and (max-width: 789px) {
  .links {
    right: 171px;
    gap: 0;
  }
  .navbar .search .cat {
    gap: 1px;
  }
}
@media screen and (max-width: 762px) {
  .navbar .search .search_wrap input {
    width: 142px;
  }
}
@media screen and (max-width: 744px) {
  .navbar .rocket span {
    font-size: small;
  }
  .navbar .img {
    width: 44px;
  }
  .navbar .search {
    right: 241px;
  }
  .links {
    right: 179px;
  }
  .links a:first-child {
    right: 38px;
  }
}

@media screen and (max-width: 730px) {
  .navbar .search .search_wrap {
    margin-left: 269px;
  }
  .search select {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    position: relative;
    top: 5px;
  }
  .navbar .search .search_wrap input {
    width: 221px;
  }
}
@media screen and (max-width: 719px) {
  .navbar .search .search_wrap input {
    width: 196px;
  }
}
@media screen and (max-width: 691px) {
  .navbar .search .search_wrap input {
    width: 175px;
  }
}
@media screen and (max-width: 674px) {
  .navbar .search .search_wrap input {
    width: 150px;
  }
}
@media screen and (max-width: 650px) {
  .links {
    right: 172px;
  }
  .logout {
    right: 23px;
  }
  .navbar .search .search_wrap input {
    width: 128px;
  }
  .logout {
    right: 21px;
    top: 9px;
  }
}
@media screen and (max-width: 790px) {
}
@media screen and (max-width: 650px) {
}
@media screen and (max-width: 624px) {
  .navbar .img {
    width: 36px;
  }
  .navbar .rocket span {
    font-size: 0.1rem;
  }
  .logout {
    right: 30px;
  }
  .search .image {
    position: relative;
    left: 20px;
  }
  .navbar .search .search_wrap {
    visibility: hidden;
    user-select: none;
    cursor: none;
  }
  .navbar .search .cat {
    left: 397px;
    top: 3px;
  }
  .links a:first-child {
    right: 38px;
    margin: 0 1px;
  }
}
@media screen and (max-width: 614px) {
  .navbar .search {
    right: 262px;
  }
  .links {
    right: 194px;
    top: 2px;
  }
}
@media screen and (max-width: 590px) {
  .navbar .search {
    right: 291px;
  }
  .links {
    right: 223px;
    top: 2px;
  }
  .logout {
    right: 16px;
  }
}
@media screen and (max-width: 576px) {
  .logout {
    right: 30px;
  }
}
@media screen and (max-width: 564px) {
  .navbar .search {
    right: 303px;
  }
  .links {
    right: 236px;
  }
  .navbar .search .cat {
    left: 422px;
    top: 3px;
  }
  .search .image {
    position: relative;
    left: 20px;
    width: 26px;
    height: 26px;
    top: 8px;
  }
  .logout {
    right: 35px;
    top: 0px;
  }
  .links a:first-child {
    right: 38px;
    margin: 0 6px;
    top: -2px;
  }
  .search .image {
    top: 5px;
  }
  .navbar .search .cat {
    left: 422px;
    top: 0px;
  }
}
@media screen and (max-width: 548px) {
  .links {
    right: 256px;
  }
  .navbar .search {
    right: 328px;
  }
}
@media screen and (max-width: 520px) {
  .navbar .search {
    right: 350px;
  }
  .links {
    right: 292px;
  }
}
@media screen and (max-width: 494px) {
  .navbar .search {
    right: 368px;
  }
  .links {
    right: 300px;
  }
}
@media screen and (max-width: 480px) {
  .navbar .search {
    right: 378px;
  }
  .links {
    right: 312px;
  }
}

@media screen and (max-width: 454px) {
  .navbar .search {
    right: 388px;
  }
  .links {
    right: 325px;
  }
}
@media screen and (max-width: 446px) {
  .navbar .rocket span {
    display: none;
  }
  .links {
    right: 270px;
  }
  .navbar .search {
    right: 334px;
  }
}
@media screen and (max-width: 437px) {
  .navbar .search {
    right: 352px;
  }
  .links {
    right: 285px;
  }
}
@media screen and (max-width: 420px) {
  .links {
    display: none;
    right: 285px;
  }
  .navbar .search {
    right: 238px;
  }
  .hamberger {
    position: fixed;
    right: 20px;
  }
}
@media screen and (max-width: 410px) {
  .navbar .search {
    right: 250px;
  }
}
@media screen and (max-width: 396px) {
  .navbar .search {
    right: 265px;
  }
}
@media screen and (max-width: 382px) {
  .navbar .search {
    right: 275px;
  }
}
@media screen and (max-width: 374px) {
  .navbar .search {
    right: 290px;
  }
}
@media screen and (max-width: 358px) {
  .navbar .search {
    right: 305px;
  }
}
@media screen and (max-width: 350px) {
  .navbar .search {
    right: 310px;
  }
}

.extra {
  margin-top: 7px !important;
}

.sidebar ul li:nth-child(2) {
    padding: 10px;
    background: repeating-linear-gradient(317deg, #8071e5, transparent 100px)
}


