.WritePost{
    min-height: 100vh;
    width: 100%;
    position: relative;

}
.WritePost .editor{
    width: 70%;
    margin: auto;
}


.WritePost .editor .form {
  position: relative;
  top: 95px;
}

.form .photoButton {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: var(--main);
  padding: 0.41rem;
  transition: all .2s ease-in-out;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
}
.form .photoButton:hover{
    background-color: rgb(255, 177, 7);
}

.selectedImg {
    height: 400px;
    border: 1px solid black;
    width: 100%;
  }
.selectedImg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .WritePost .editor .form input[type="file"] {
    display: none;
  }
  
  .WritePost .editor .form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .WritePost .editor .form input[type="text"] {
    display: block;
    width: 98.5%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 16px;
  }  

  .editor select {
    font-size: 14px;
    padding: 8px;
    border-radius: 3px;
    border: none;
    background-color: #b6b6b6;
    color: rgb(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 10px;
  }
  
  .editor select:hover {
    background-color: #9f9f9f;
  }
  
 .editor select:focus {
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }

  .editor button[type=submit] {
    width: 100%;
    padding: 0.6rem;
    margin: 0.2rem;
    background: rgb(25, 190, 25);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    font-size: 1.2rem;
    font-weight: 550;
    cursor: pointer;
    margin-top: 10px;
}


.editor button[type=submit]:hover{
    background: rgb(30, 226, 30);
    z-index: 100000;
} 

.WritePost .editor .errorPopup{
    text-align: center;
    width: 98.5%;
    padding: .6rem;
    margin: 0.2rem;
    background: rgb(255, 0, 0);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    z-index: 100000;
    margin-top: 10px;
}

@media screen and (max-width:645px) {
  .WritePost .editor {
    width: 78%;
    margin: auto;
}

}
@media screen and (max-width:535px) {
  .WritePost .editor {
    width: 83%;
    margin: auto;
}

}
@media screen and (max-width:431px) {
  .selectedImg {
    height: 312px;
}

}

