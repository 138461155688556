.ArticlePage{
    /* background-color: rgb(164, 96, 96); */
    width: 100%;
}
.article{
    background-color: white;
    width: 60%;
    margin: auto;
    position: relative;
    top: 95px;
    padding: 1rem;

}

.article .user_info{
   display: flex;
   gap: 1rem;
}
.article .user_info .user_image{
   height: 80px;
   width: 80px;
   border-radius: 50%;
   overflow: hidden;
   object-fit: cover;
}
.article .user_info .user_image img{
   width: 100%;
   height: 100%;
}

.article .user_info .user_side{
   display: flex;
   flex-direction: column;
   gap: 2px;
   justify-content: center;
   width: calc(100% - 80px) ;
}
.article .user_info .user_side span:first-of-type{
   font-weight: 500;
   font-family: 'Times New Roman', Times, serif;
}
.article .user_info .user_side span:first-of-type a{
   text-decoration: none;
   color: black;
   font-weight: 400;
}
.article .user_info .user_side span:first-of-type a:hover{
   text-decoration: underline;
}
.article .user_info .user_side span:last-of-type{
   color: grey;
   font-size: 1.3rem;
}

.article img{
    width: 100%;
    object-fit: cover;
}

.article .post_date{
   color: black;
   font-size: xx-small;
   font-weight: 500;
}
.article .article_title{
    margin-top: 1.5rem;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Aclonica', sans-serif;
}
.article .article_image{
    margin-top: 1.5rem;
}
.article .article_content{
   overflow-x: scroll;
    margin-top: 1.5rem;
}
.article .article_content img{
    object-fit: contain;
}

@media screen and (max-width:1008px) {
   .article {
      width: 70%;
  }
}
@media screen and (max-width:641px) {
   .article {
      width: 80%;
  }
}
@media screen and (max-width:500px) {
   .article {
      width: 85%;
  }
}
@media screen and (max-width:500px) {
   .article {
      width: 95%;
  }
}

