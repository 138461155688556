.user-search {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .user-search label {
    display: block;
    margin-bottom: 10px;
  }
  
  .user-search input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .user-search button {
    display: block;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .user-search button:hover {
    background-color: #0062cc;
  }
  
  .user-search p {
    margin-top: 10px;
  }
  
  @media only screen and (max-width: 600px) {
    .user-search {
      max-width: 100%;
      padding: 10px;
    }
    
    .user-search input[type="text"] {
      font-size: 14px;
      margin-bottom: 10px;
    }
    
    .user-search button {
      font-size: 14px;
      padding: 8px;
      margin-bottom: 10px;
    }
    
    .user-search p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  