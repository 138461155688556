.posts_container {
  margin: 1.2rem 8rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: auto;
  grid-gap: 1.2rem;
}
.posts_container .item{
    border: .5px solid rgb(206, 200, 200);
    display: flex;
    gap: 2.3rem;
    transition: all 0.2s;
     border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 12px -2px rgba(97, 97, 97, 0.69);
  -moz-box-shadow: 0px 1px 12px -2px rgba(97, 97, 97, 0.69);
  box-shadow: 0px 1px 12px -2px rgba(97, 97, 97, 0.69);
}
.posts_container .item:hover {
  transform: scale(1.02);
  -webkit-box-shadow: 1px 2px 6px 0px rgba(161, 152, 161, 1);
  -moz-box-shadow: 1px 2px 6px 0px rgba(161, 152, 161, 1);
  box-shadow: 1px 2px 6px 0px rgba(161, 152, 161, 1);
}
.posts_container .item .left {
  width: 40%;
  height: 250px;
}
.posts_container .item .right {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.posts_container .item .right .title {
  color: black;
  font-size: 1rem;
  line-height: 1.25;
  cursor: pointer;
  flex: 4;
}
.posts_container .item .right .title:hover {
  color: rgb(71, 65, 65);
}
.posts_container .item .right .description {
  padding-right: 5px;
  color: #888;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 400;
  flex: 4;
  overflow: hidden;
}
.posts_container .item .right .profile_data {
  display: flex;
  padding: 5px;
  gap: 0.6rem;
  position: relative;
  top: -15px;
  left: -5px;
  cursor: pointer;
  flex: 2;
  align-items: center;

  /* border: 5px solid red; */
}
.posts_container .item .right .profile_data .user_image {
  background: white;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.posts_container .item .right .profile_data .user_middle {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.posts_container .item .right .profile_data .user_middle span:first-of-type {
  font-family: "Times New Roman", Times, serif;
}
.posts_container .item .right .profile_data .user_middle span:first-of-type a {
  text-decoration: none;
  color: #0e0d0d;
}
.posts_container
  .item
  .right
  .profile_data
  .user_middle
  span:first-of-type
  a:hover {
  text-decoration: underline;
  color: #001392;
}
.posts_container .item .right .profile_data .user_middle span:last-of-type {
  color: #7d7d7d;
  font-size: small;
}

.posts_container .item .right .profile_data .savePost {
  position: absolute;
  right: 30px;
  top: 8px;
}
.posts_container .item .right .profile_data .savePost svg {
  fill: black;
}
.posts_container .item .right .profile_data .savePost svg:hover {
  fill: rgb(90, 82, 82);
}

.posts_container .item .right .profile_data .user_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.posts_container .item .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* set the height to match the container height */
}

.post_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 20px;
}

@media screen and (max-width: 1153px) {
  .posts_container .item .right .title {
    font-size: 1rem;
  }
  .posts_container .item .left {
    height: 225px;
  }
}
@media screen and (max-width: 1058px) {
  .posts_container .item {
    gap: 0.7rem;
  }
  .posts_container .item .right .title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1025px) {
  .posts_container {
    margin: 0.2rem 3rem;
  }
  .posts_container .item .right .title {
    font-size: 1rem;
  }
  .breaker {
    margin: 0.4rem 3rem;
  }
}
@media screen and (max-width: 966px) {
  .posts_container .item .left {
    height: 213px;
  }
  .posts_container .item .right .title {
    font-size: 0.98rem;
  }
}
@media screen and (max-width: 936px) {
  .posts_container .item .right .profile_data {
    top: -11px;
  }
  .posts_container .item .right .profile_data .user_image {
    height: 32px;
    width: 32px;
  }
  .posts_container .item .right .profile_data {
    padding: 2px;
  }
  .posts_container .item .left {
    height: 198px;
  }
}

@media screen and (max-width: 872px) {
  .posts_container {
    margin: 0.2rem 2rem;
  }
}
@media screen and (max-width: 820px) {
  .posts_container .item .right .description {
    font-size: 0.7rem;
    line-height: 1.1;
  }
  .breaker {
    margin: 0.4rem 2rem;
  }
}
@media screen and (max-width: 730px) {
  .posts_container .item .right .title {
    font-size: 0.79rem;
  }
  .links a:first-child span {
    font-size: small;
    font-weight: 900;
  }
  .posts_container .item .right .description {
    font-size: 0.4rem;
  }
  .posts_container {
    margin: 0.2rem 1rem;
  }
  .breaker {
    margin: 0.4rem 1rem;
  }
  .posts_container .item .right .profile_data .savePost {
    position: absolute;
    right: 11px;
    top: 8px;
  }
}
@media screen and (max-width: 682px) {
  .posts_container .item .right .title {
    font-size: 0.7rem;
    margin-top: 13px;
  }
  .posts_container .item .right .description {
    font-size: 0.4rem;
    position: relative;
    bottom: 14px;
  }
}
@media screen and (min-width: 682px) {
  .posts_container .item .right .title {
    margin-top: 13px;
  }
}
@media screen and (min-width: 1160px) {
  .posts_container .item .right .title {
    font-size: 1.11rem;
  }
}
@media screen and (min-width: 1212px) {
  .posts_container .item .right .title {
    font-size: 1.22rem;
  }
}
@media screen and (min-width: 1252px) {
  .posts_container .item .right .title {
    font-size: 1.32rem;
  }
}
@media screen and (max-width: 650px) {
  .posts_container .item {
    flex-direction: column;
  }
  .posts_container .item .left {
    width: 100%;
    height: 250px;
  }
  .posts_container .item .right {
    width: 100%;
  }
  .posts_container .item .right .title {
    font-size: 1.21rem;
    margin-top: -7px;
    line-height: 20px;
    padding: 5px;
  }
  .posts_container .item .right .description {
    font-size: 1rem;
    padding: 5px;
    margin-top: 17px;
  }
  .posts_container .item .right .profile_data {
    left: 0px;
    padding: 5px;
  }
  .posts_container .item .right .profile_data .user_image {
    height: 45px;
    width: 45px;
  }
  .posts_container .item .right .profile_data .savePost {
    right: 25px;
    top: 13px;
  }
  .posts_container {
    grid-gap: 0.8rem;
  }
}
@media screen and (max-width: 590px) {
  .posts_container .item .left {
    width: 100%;
    height: 185px;
  }
  .posts_container .item .right .title {
    font-size: 1rem;
    line-height: 17px;
  }
}
@media screen and (max-width: 524px) {
  .posts_container .item .left {
    width: 100%;
    height: 149px;
  }
  .posts_container .item .right .description {
    font-size: 0.7rem;
  }
  .posts_container .item .right .profile_data .user_image {
    height: 35px;
    width: 35px;
  }
}
@media screen and (max-width: 520px) {
  .posts_container .item .right .title {
    font-size: 0.89rem;
  }
}
@media screen and (max-width: 437px) {
  .posts_container .item .left {
    width: 100%;
    height: 120px;
  }
  .posts_container .item .right .title {
    font-size: 0.82rem;
  }
}
@media screen and (max-width: 420px) {
  .posts_container {
    grid-template-columns: 1fr;
  }
  .posts_container .item .left {
    width: 100%;
    height: 194px;
  }
  .posts_container .item .right .title {
    font-size: 1.2rem;
    line-height: 27px;
  }
  .posts_container .item .right .profile_data {
    padding: 13px;
  }
  .posts_container .item .right .profile_data .savePost {
    right: 40px;
    top: 15px;
  }
  .posts_container p b {
    font-size: xx-small;
  }
  .posts_container .item {
    -webkit-box-shadow: 1px 2px 3px 0px rgba(189, 189, 189, 1);
    -moz-box-shadow: 1px 2px 3px 0px rgba(189, 189, 189, 1);
    box-shadow: 1px 2px 3px 0px rgba(189, 189, 189, 1);
    flex-direction: column;
  }
}
